<template>
  <!-- 今日学习 -->
  <div class="study">
    <!-- 背景图 -->
    <img src="@/assets/img/Head_bg2.png" alt="" class="bg_img" />
    <div class="select">
      <div><span :class="{ active: type == 1 }">今日学习</span></div>
      <div>
        <span :class="{ active: type == 2 }" @click="types">学习记录</span>
      </div>
    </div>
    <div class="wrap">
      <!-- 今日学习 -->
      <template v-if="type == 1">
        <div class="title">
          <div class="p1">{{ info.book_content }}</div>
          <div class="p2">——{{ info.book_name }}</div>
        </div>
        <div class="audio">
          <img
            src="@/assets/img/stop.png"
            alt=""
            v-if="!play_show"
            @click="play"
          />
          <img src="@/assets/img/play3.png" alt="" v-else @click="stop" />
          <audio
            :src="info.file"
            controls="controls"
            @canplay="getDuration"
            @timeupdate="updateTime"
            ref="audio"
            style="display: none"
          ></audio>
          <div class="box">
            <span style="height: 50%; left: 0px" :class="{ bi: bi > 5 }"></span>
            <span
              style="height: 70%; left: 15px"
              :class="{ bi: bi > 10 }"
            ></span>
            <span
              style="height: 30%; left: 30px"
              :class="{ bi: bi > 15 }"
            ></span>
            <span
              style="height: 50%; left: 45px"
              :class="{ bi: bi > 20 }"
            ></span>
            <span
              style="height: 100%; left: 60px"
              :class="{ bi: bi > 25 }"
            ></span>
            <span
              style="height: 100%; left: 75px"
              :class="{ bi: bi > 30 }"
            ></span>
            <span
              style="height: 70%; left: 90px"
              :class="{ bi: bi > 35 }"
            ></span>
            <span
              style="height: 100%; left: 105px"
              :class="{ bi: bi > 40 }"
            ></span>
            <span
              style="height: 70%; left: 120px"
              :class="{ bi: bi > 45 }"
            ></span>
            <span
              style="height: 50%; left: 135px"
              :class="{ bi: bi > 50 }"
            ></span>
            <span
              style="height: 100%; left: 150px"
              :class="{ bi: bi > 55 }"
            ></span>
            <span
              style="height: 50%; left: 165px"
              :class="{ bi: bi > 60 }"
            ></span>
            <span
              style="height: 70%; left: 180px"
              :class="{ bi: bi > 65 }"
            ></span>
            <span
              style="height: 50%; left: 195px"
              :class="{ bi: bi > 70 }"
            ></span>
            <span
              style="height: 100%; left: 210px"
              :class="{ bi: bi > 75 }"
            ></span>
            <span
              style="height: 70%; left: 225px"
              :class="{ bi: bi > 80 }"
            ></span>
            <span
              style="height: 50%; left: 240px"
              :class="{ bi: bi > 85 }"
            ></span>
            <span
              style="height: 100%; left: 255px"
              :class="{ bi: bi > 90 }"
            ></span>
            <span
              style="height: 70%; left: 270px"
              :class="{ bi: bi > 95 }"
            ></span>
            <span
              style="height: 50%; left: 285px"
              :class="{ bi: bi >= 100 }"
            ></span>
          </div>
          <div class="time">{{ time }}</div>
          <div class="all_time">{{ all_time }}</div>
        </div>
        <div class="info">
          <div class="p1">注释</div>
          <div class="p2" v-html="info.annotation"></div>
        </div>
      </template>
      <!-- 学习记录 -->
      <!-- <template v-else>
        <div class="list">
          <div class="box" v-for="(item,index) in jilu" :key="index">
            <div class="p1">{{item.book_content}}</div>
            <div class="p2">——{{item.book_name}}</div>
            <div class="p3">完成时间：{{item.study_time}}</div>
          </div>
        </div>
      </template> -->
    </div>
    <div class="wechat_popup" @click.self="hide_wechat">
      <div class="box">
        <div class="title">分享至</div>
        <div class="imgs">
          <img src="@/assets/img/haoyou.png" alt="" @click="sign(1)" />
          <img src="@/assets/img/pengyouquan.png" alt="" @click="sign(0)" />
        </div>
      </div>
    </div>
    <!-- 提示 -->
    <fadeAlert
      :msg="msg"
      v-if="showAlert"
      @hideFade="showAlert = false"
    ></fadeAlert>
    <loading :ifload="ifload"></loading>
  </div>
</template>

<script>
import fadeAlert from "@/components/fadeAlert";
import loading from "@/components/loading";
export default {
  components: {
    fadeAlert,
    loading,
  },
  data() {
    return {
      headerName: "每日一句经典学习",
      msg: "", // 提示内容
      showAlert: false, // 提示显示
      ifload: false, // loading
      type: 1,
      info: {},
      jilu: [],
      play_show: false,
      time: "00:00", // 当前时间
      all_time: "", // 总时间
      bi: "", // 当前百分比
      all: "", // 总秒数
      share_show: false,
      user: {},
    };
  },
  created() {
    if (this.$route.query.token) {
      localStorage.setItem("token", this.$route.query.token);
    }
  },
  mounted() {
    var time = this.$route.query.time;
    if (time == undefined) {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      time = year + "-" + month + "-" + day;
    }
    // 今日学习
    this.$get(`/ClockInc/everydayStudy?ymd=${time}`, {}, (res) => {
      if (res.data.code == 1) {
        this.info = res.data.data;
      } else if (res.data.code == 2) {
        this.$router.replace({
          name: "study_buy",
          query: { url: res.data.data.url },
        });
      } else {
        this.msg = res.data.msg;
        this.showAlert = true;
      }
    });
    // 获取用户信息
    this.$get("/User/index", {}, (res) => {
      if (res.data.code == 1) {
        this.user = res.data.data;
      }
    });
  },
  methods: {
    // 显示分享
    show_share() {
      $(".wechat_popup").css("display", "block");
      $(".wechat_popup > .box").animate({
        bottom: 0,
      });
    },
    // 隐藏微信分享
    hide_wechat() {
      $(".wechat_popup > .box").animate({
        bottom: -120 + "px",
      });
      setTimeout(() => {
        $(".wechat_popup").css("display", "none");
      }, 400);
    },
    second(value) {
      var theTime = parseInt(value); // 秒
      var middle = 0; // 分
      if (theTime > 60) {
        middle = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);
        if (middle > 60) {
          middle = parseInt(middle % 60);
        }
      }
      if (theTime < 10) {
        var result = "0" + parseInt(theTime);
      } else {
        result = "" + parseInt(theTime);
      }
      if (middle > 0) {
        if (middle < 10) {
          result = "0" + parseInt(middle) + ":" + result;
        } else {
          result = "" + parseInt(middle) + ":" + result;
        }
      } else {
        result = "0" + parseInt(middle) + ":" + result;
      }
      return result;
    },
    getDuration() {
      var s = this.$refs.audio.duration; // 总时长
      this.all_time = this.second(s);
      this.all_s = s;
    },
    updateTime(e) {
      var s = e.target.currentTime; // 获取audio当前播放时间
      this.time = this.second(s);
      this.bi =
        (
          Number(s.toFixed(2) * 100) / Number(this.all_s.toFixed(2) * 100)
        ).toFixed(2) * 100;
    },
    play() {
      $("audio")[0].play();
      this.play_show = true;
    },
    stop() {
      $("audio")[0].pause();
      this.play_show = false;
    },
    // 顶部切换
    types() {
      this.$router.push({ name: "Signin" });
    },
    // 签到
    sign(type) {
      this.$post("/ClockInc/dayClock", { type: 1 }, (res) => {
        if (res.data.code == 1) {
          this.msg = "签到成功";
          this.showAlert = true;
        } else {
          this.msg = res.data.msg;
          this.showAlert = true;
        }
      });
    },
  },
  updated() {
    $(".info").find(".p2").find("img").css("width", "100%");
  },
};
</script>

<style lang='scss' scoped>
.study {
  background-color: #f2ecd4;
  // 背景图
  .bg_img {
    position: fixed;
    top: 0px;
    width: 100%;
    height: 60px;
    z-index: 2;
  }
  // 选项
  .select {
    z-index: 4;
    position: fixed;
    top: 0px;
    width: 100%;
    display: flex;
    border-top: 3px solid white;
    padding-top: 10px;
    div {
      flex: 1;
      text-align: center;
      span {
        font-size: 20px;
        color: #444444;
      }
    }
  }
  .wrap {
    position: absolute;
    top: 60px;
    width: 94.5%;
    background: #f2ecd4;
    padding: 0 10px;
    // 今日学习
    .title {
      padding: 20px 10px;
      background-image: url("../../assets/img/every_bg.png");
      background-size: 100% 100%;
      .p1 {
        font-size: 16px;
      }
      .p2 {
        font-size: 16px;
        text-align: right;
        margin-top: 10px;
      }
    }
    .audio {
      display: flex;
      align-items: center;
      padding: 10px 15px 25px 15px;
      background-image: url("../../assets/img/every_bg.png");
      background-size: 100% 100%;
      margin-top: 10px;
      position: relative;
      img {
        width: 35px;
        margin-right: 20px;
        margin-top: 20px;
      }
      .box {
        position: relative;
        display: flex;
        width: 100%;
        height: 40px;
        overflow: hidden;
        span {
          position: absolute;
          bottom: 0;
          width: 5px;
          background: #aaaaaa;
          border-radius: 10px;
        }
      }
      .time {
        position: absolute;
        left: 70px;
        top: 65px;
        color: #666;
      }
      .all_time {
        position: absolute;
        right: 20px;
        top: 65px;
        color: #666;
      }
    }
    .info {
      padding: 20px 10px;
      background-image: url("../../assets/img/every_bg2.png");
      background-size: 100% 100%;
      margin-top: 5px;
      .p1 {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
      }
      .p2 {
        font-size: 16px;
        margin-top: 5px;
      }
    }
    // 学习记录
    .list {
      display: flex;
      flex-direction: column;
      .box {
        padding: 10px;
        background-image: url("../../assets/img/every_bg.png");
        background-size: 100% 100%;
        border-radius: 10px;
        margin-bottom: 5px;
        .p1 {
          font-size: 16px;
        }
        .p2 {
          font-size: 16px;
          text-align: right;
          margin-top: 5px;
        }
        .p3 {
          font-size: 15px;
          color: #615b5b;
          text-align: right;
          margin-top: 5px;
        }
      }
    }
  }
  .share {
    position: fixed;
    top: 14px;
    right: 10px;
    z-index: 2;
    font-size: 16px;
    font-weight: bold;
    img {
      width: 20px;
    }
  }
}
.wechat_popup {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.7);
  .box {
    position: absolute;
    bottom: -150px;
    background: white;
    width: 100%;
    height: 150px;
    border-radius: 10px 10px 0 0;
    .title {
      text-align: center;
      font-size: 16px;
      margin-top: 10px;
    }
    .imgs {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 20px;
      img {
        width: 50px;
        height: 60px;
      }
    }
  }
}
.active {
  border-bottom: 2px solid #444444 !important;
}
.bi {
  background: #ac0404 !important;
}
</style>