<template>
  <div class="loading" v-if="ifload">
    <div class="layer">
      <img :src="loadImg" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "加载中...",
    },
    ifload: {
      default: false,
    },
  },
  data() {
    return {
      loadImg: require("@/assets/img/loading.gif"),
    };
  },
};
</script>

<style type="text/css" scoped>
.loading {
  position: fixed;
  top: 0px;
  bottom: 0;
  left: 0px;
  right: 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  z-index: 99999999999;
}
.loading .layer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background: rgba(0, 0, 0, 0.4); */
  padding: 10px 20px;
  z-index: 99999999999;
  border-radius: 4px;
}
.loading .layer img {
  width: 100%;
}
.desc {
  line-height: 20px;
  font-size: 12px;
  color: #000;
  letter-spacing: 4px;
  font-weight: bold;
}
</style>